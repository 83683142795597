<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['facialDrynessLocations'])">
      <face-zone-single-select
        title="label.facialDrynessLocations"
        :gender="gender"
        :value="facialDrynessLocations"
        :options="$options.drynessLocationsOptions"
        field-name="dryness"
        @input="onFieldChange('facialDrynessLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneSingleSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSingleSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { DRYNESS_LOCATIONS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinDryness';

export default {
  name: 'DrynessLocationTemplate',
  components: { FaceZoneSingleSelect, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  drynessLocationsOptions: DRYNESS_LOCATIONS_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    facialDrynessLocations: {
      type: String,
      default: ''
    }
  }
};
</script>
